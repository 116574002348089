import { Button } from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'

const Home = () => {
  return (
    <div>
      <center>
        <br />
        <br />
        <br />
        <Button
          as={NavLink}
          exact="true"
          to="/message"
          activeclassname="isSelected"
        >
          Sign On
        </Button>
      </center>
    </div>
  )
}
export default Home
