import { Heading, Layout } from '@enterprise-ui/canvas-ui-react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider, ProtectedElement } from '@praxis/component-auth'
import '@enterprise-ui/canvas-ui-css'
import apiConfig from './apiConfig'
import Home from './components/Home'
import Prompt from './components/Message'

const App = () => {
  return (
    <AuthProvider {...apiConfig.auth}>
      <BrowserRouter>
        <Layout theme="target" darkMode="system">
          <Layout.Header style={{ textAlign: 'center' }}>
            <Heading size={1}>Crisis Messaging</Heading>
          </Layout.Header>
          <Layout.Body>
            <Routes>
              <Route exact="true" path="/" element={<Home />} />
              <Route
                exact="true"
                path="/message"
                element={
                  <ProtectedElement allowed={apiConfig.auth.adGroup}>
                    <Prompt />
                  </ProtectedElement>
                }
              />
            </Routes>
          </Layout.Body>
        </Layout>
      </BrowserRouter>
    </AuthProvider>
  )
}
export default App
